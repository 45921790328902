.input-group-append .ant-btn { border-radius: 0 }

/*.avatar-uploader .ant-upload, .avatar-upload .ant-upload, .ant-upload.ant-upload-select-picture-card { max-height: 250px; width: 100% !important; height: fit-content !important;  height: auto !important; max-width: 250px; }*/
.ant-upload-picture-card-wrapper { text-align: center; width: 100%; }

.ant-card-body { padding: 24px; }

.ant-input { border-radius: 4px; }
.ant-form-item { margin: 8px 0 !important;
  //height: 60px;
}

.ant-form-item-explain, .ant-form-item-extra { clear: both; min-height: 24px; padding-top: 0px; color: rgba(0, 0, 0, 0.45); font-size: 14px; line-height: 1.5715; transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); color: #ff0000; }

.ant-alert { margin-bottom: 15px }

/*----------Ant Misc------------*/
/*.ant-upload.ant-upload-select-picture-card { width: 100%; padding-top: 100%; position: relative; margin-right: 0px !important; margin-bottom: 0px !important; text-align: center; vertical-align: top; background-color: #ffffff; border: 1px solid #eaeaea; border-radius: 8px; cursor: pointer; transition: border-color .3s; }
  .ant-upload.ant-upload-select-picture-card div .ng-star-inserted { position: absolute; top: 0; bottom: 0; right: 0; left: 0; padding-top: 35%; }
*/
.ant-page-header { padding: 0px 8px 12px 8px !important }
.ant-page-header-heading-title { font-family: Georgia; font-weight: normal !important; font-size: 22px !important; }
.ant-page-header-heading-title i { margin-right: 8px; top: -5px; position: relative; }


@media (max-width: 767px) {
  .ant-page-header-heading-extra { margin: 4px 0; width: 100%; white-space: nowrap; display: block }
  .ant-page-header-heading-extra nz-select { width: 100%; }
  .ant-page-header { padding: 0px 0px 12px 0px !important }
  .avatar-upload .ant-upload, .ant-upload.ant-upload-select-picture-card { max-width: 256px; max-height: 256px; }
}


/*----------Ant Form------------*/
.ant-form label, .ant-form-item-label { display: inline-block !important; flex-grow: 0; overflow: hidden; white-space: nowrap; text-align: right; vertical-align: middle !important; line-height: 50px; }
/*{ background-color: #ffffff; border-color: #b0b0b0; color: #475b66; height: 50px; font-family: Georgia; border-radius: 6px; border: 1px solid #ddd; padding: 0 8px; }*/

nz-input-group { overflow: hidden; }


.ant-input-group-addon i { font-size: 18px !important; vertical-align: middle !important; margin-top: -5px !important; }
.ant-input-affix-wrapper { height: 50px; position: relative; display: inline-block; width: 100%; min-width: 0; padding: 0 15px; color: rgba(0, 0, 0, 0.85); font-size: 14px; line-height: 50px; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-radius: 4px; transition: all 0.3s; display: inline-flex; }
.ant-input-affix-wrapper { padding-top: 0px; padding-bottom: 0px; position: relative; }

.ant-input-affix-wrapper > input.ant-input { padding: 0; border: none; outline: none; height: 50px; line-height: 50px; font-family: 'Jost'; }
.ant-input-prefix { margin-right: 8px; }

/*-----------Table---------------*/
.ant-table-column-sorter-full { line-height: 16px; }
.ant-table-column-sorter-inner i { line-height: 8px }
.ant-pagination-next .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link { line-height: 10px; }
.anticon { color: inherit; font-style: normal; text-align: center; }

.ant-form-item-control-input { position: relative; display: flex; align-items: center; min-height: 50px; height: 50px; }
/*.ant-form-item-control-input input { border: 1px solid #b0b0b0 !important; height: 50px !important; }*/
.ant-form-item-control-input input { }
.ant-form-item .ant-select { width: 120px; }
.ant-form-item-control-input button { }
.ant-form-item-label > label { height: 50px; }

.filter .ant-select-multiple .ant-select-selector { height: 50px; flex-wrap: inherit; border-radius: 4px; }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector { border: 0; border-radius: 26px; height: 50px }

/*-------------Tags--------*/
/*.ant-form-item-control-input input { border: 1px solid #b0b0b0 !important; height: 50px !important; padding: 0 8px; }*/
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover { color: #ffffff; background-color: #7ac8bc; border-color: #475b66; }
.ant-tag-checkable:not(.ant-tag-checkable-checked) { color: #303e46; background-color: #ffffff; border-color: #808080; }
.ant-tag-checkable-checked { color: #ffffff; border-color: #808080; border: 1px; }

.ant-tag { border-radius: 4px; }

/*
  .ant-tag { border-color: #808080; box-sizing: unset; margin: 5px; font-variant: normal; display: inline-block; white-space: unset; cursor: default; font-size: 1.2rem; font-family: 'Jost', sans-serif; min-height: 30px; line-height: 36px; vertical-align: middle; border-radius: 8px; margin: 5px; border: 1px; border-style: solid; padding: 5px; padding-left: 15px; padding-right: 15px; }
    .ant-tag:hover { color: #ffffff; background-color: #7ac8bc; border-color: #475b66; }
*/
/*------------Dropdowns------*/
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) { color: rgba(0,0,0,.85); font-weight: 600; background-color: #d9eae6; }

.ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector { border-color: transparent; box-shadow: 0 0 0 2px #489b8c; border-radius: 4px; }
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector { border-color: #d9eae6; box-shadow: 0 0 0 2px rgba(122, 200, 188, 0.95); }
.ant-select:not(.ant-select-disabled):hover .ant-select-selector { border-color: #7ac8bc; }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector { border: 1px solid #CCC; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector { border-radius: 4px !important; }
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder { line-height: 50px; vertical-align: middle; height: 50px; }
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder { padding: 0; line-height: 45px !important; transition: all 0.3s; }
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder { padding-right: 18px; line-height: 46px; }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector { position: relative; background-color: #fff; border: 1px solid #d9d9d9; border-radius: 2px; transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); width: 100%; height: 50px !important; line-height: 46px; padding: 0 11px; }

.ant-select-item-option-content img { display: inline-block !important; max-width: 20px !important; margin-right: 4px; }

/*-------------Buttons--------*/


.ant-btn { font-family: 'Jost'; text-transform: uppercase; line-height: 50px; position: relative; display: inline-block; font-weight: 400; white-space: nowrap; text-align: center; background-image: none; border: 1px solid #CCC; box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015); cursor: pointer; transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; touch-action: manipulation; height: 50px; padding: 0px 15px; font-size: 14px; border-radius: 8px; color: #000; border: 1px solid #303e46; }
.ant-btn .anticon { transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); line-height: 46px; vertical-align: top; top: 0; display: inline-block; margin: 0; }

.ant-btn-primary { color: #fff; background: #303e46; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); color: #FFF; box-shadow: 0 2px 0 rgba(0,0,0,.045); }
.ant-btn-primary:focus, .ant-btn-primary:hover { color: #fff; background: #489b8c; border-color: #489b8c; }

.ant-btn-loading .anticon-loading { vertical-align: top !important; height: 50px; line-height: 50px !important; margin: 0; display: inline-block; padding: 0; top: 0; margin-top: 0; margin-right: 4px; }

.ant-btn-circle .anticon { line-height: 23px !important; vertical-align: top; }

/*-------------Checkbox-------*/


/*-------------Icon-------*/

.ant-btn-icon-only { width: 32px; height: 32px; padding: 2.4px 0; font-size: 16px; vertical-align: 0px; }

/*------------Tabs-----------*/
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list { position: relative; display: flex; transition: transform 0.3s; margin: auto; text-align: center; display: inline-block; }
/*.ant-tabs-nav-list { margin: auto; }*/
.ant-tabs-tab { font-size: 20px; }
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab { padding: 16px 0; font-size: 20px !important; }
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab { padding: 15px 0; font-size: 14px !important; }

.ant-tabs-ink-bar { background: #57b4a2 }
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { color: #57b4a2; }
.ant-tabs-tab:hover { color: #57b4a2; }
.ant-tabs-tab-btn:active, .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:active, .ant-tabs-tab-remove:focus, .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus { color: #548d82; }
.tab-actions { background-color: #fbfbfb }
.tab-actions p { text-align: right; width: 100%; }

/*------------Info Message----------*/
.ant-message .anticon { position: relative; top: -3px !important; }






/*------------Upload----------*/
.ant-upload { position: relative }
.ant-upload .ant-actions { position: absolute; top: 8px; right: 8px; }
