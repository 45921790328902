
.inviteListHeading { font-size: 14px; text-align: left; }
.inviteListProfileCard { font-size: 14px; text-align: center; }

.inviteSelectInput::-webkit-input-placeholder { color: #3e3e3e !important; }

.inviteSelectInput:-ms-input-placeholder { color: #3e3e3e !important; }

.inviteSelectInput::placeholder { color: #3e3e3e !important; }




/*----------invite list card--------------*/

.profileCardHolder .ant-card-body { padding: 3px !important; }
.iconHolder { font-size: 18px; position: absolute; top: 0px; right: 4px; z-index: 1; color: #ffffff; }

.iconHolder svg { background: #2c9cdb; border-radius: 50%; color: #ffffff; }

.cardSelected { border: 0px solid #9fd3ca !important; }
.cardNotSelected { border: 0px }
.showIcon { display: inline-block !important; }
.noIcon { display: none !important; }
.buttonIcon { line-height: 2 !important; }

.smallInputHolder { height: 24px !important; padding-right: 0px !important; margin: 0; }
.smallInputGroup { height: 24px !important; line-height: 24px !important; top: 0px !important; }

.normalInputHolder { height: 79px; vertical-align: top; overflow: hidden; margin: 0; padding: 0 15px; margin-top: 8px; }
.normalInputGroup { height: 50px; }
