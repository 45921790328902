
/*------------News Feed-------------*/
.ngucarousel { padding: 0; position: absolute; width: calc(100%); height: 800px; overflow: hidden; white-space: nowrap; }
.ngucarousel .carousel-news-item { background: rgba(0,0,0,0); padding: 0 15px 0 0; border-radius: 0; display: -webkit-box; display: -ms-flexbox; display: inline-block; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-transition: .2s; transition: .2s; margin: 0; display: flow-root; overflow: hidden; }
.ngucarousel .carousel-news-item h2 { padding: 0; margin: 0; line-height: 36px; margin-bottom: 0; font-size: 16px; font-family: 'Jost',sans-serif; }
.ngucarousel .tile { box-shadow: none !important }
/*.ngucarousel .carousel-news-item:hover { max-width: 180px !important; width: 180px; }*/

.ngucarousel .carousel-news-item > .image { }
.ngucarousel .carousel-news-item > .text { height: 100px; min-height: 64px; }
.ngucarousel .carousel-news-item > .image img { width: 100%; margin-bottom: 0; max-height: 40vh; object-fit: cover; height: 200px; }
.ngucarousel .carousel-news-item > .text { overflow: hidden; font-size: 11px; }
.ngucarousel .carousel-news-item > .text p { font-size: 11px; }

/*h1 { min-height: 200px; background-color: #ccc; text-align: center; line-height: 200px; }*/
.leftRs, .rightRs { position: absolute; top: 20%; bottom: 0; width: 36px; height: 36px; z-index: 10000; /* box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, .3); */ border-radius: 50%; background: rgba(0,0,0,0.5); border: 0; color: #fff; }
.leftRs { left: 15px; }
.rightRs { right: 15px }

@media (max-width: 576px) {
  .ngucarousel .carousel-news-item { padding: 0 0 0 0; }
}

.ngucarousel > .ngu-touch-container > .ngucarousel-items > .item { flex: 0 0 50%; width: 50%; padding: 0 15px 0 0 !important; }
