
/*-------Members---------*/
.member-page .ant-tabs { height: 100%; }
.member-page .ant-tabs-content { height: 100%; }
/*:host ::ng-deep .ant-tabs-nav { padding-right: 30px }*/

.media-popover { border-radius: 8px; display: block; width: 480px; height: 360px; object-fit: contain; background: white; }
.media-popover .ant-popover-inner-content { padding: 15px; color: rgba(0, 0, 0, 0.85); }
.media-popover .ant-popover-inner { background-color: #fff; background-clip: padding-box; border-radius: 8px; box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9; }

.transparent-separator { background: url(/assets/img/body-bg.jpg) repeat; height: 30px; width: 100%; }

.member { }
.member-inner .image { border-radius: 50%; height: 80px; width: 80px; overflow: hidden; display: flex; justify-content: center; align-items: center; margin: auto; margin-bottom: 8px; }
.member-inner .text { height: 108px; }
.member-inner img { width: 100%; margin: auto; height: 100%; object-fit: cover }
.member-inner h2 { min-height: 46px; text-align: center; font-size: 20px; margin-bottom: 6px; }
.member-inner h3 { min-height: 20px; text-align: center; font-size: 14px; margin-bottom: 8px; font-family: Georgia !important; }
.member-inner p { text-align: center; font-size: 12px; margin: auto; text-align: center; display: block; height: 40px }
.member-inner p.answer { }

@media (max-width: 1200px) {
  .member-inner h2 {font-size: 18px; }
}

