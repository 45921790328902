
/*-----------Interests--------------*/
.interestsList .ant-tag { line-height: 30px; font-size: 14px; border: 0; border-radius: 8px; min-height: 30px; padding-left: 15px; padding-right: 15px; margin-bottom: 8px; font-family: 'Jost', sans-serif; color: #000000 }


.interestsList:not(.readonly) .ant-tag:not(.ant-tag-checkable-checked) { border: 1px solid #bebebe; color: #000; background-color: #ffffff !important; }
/*.interestsList:not(.readonly) .ant-tag.ant-tag-checkable-checked { background-image: url(./assets/img/black-gradient.png); background-position: bottom; }*/
.interestsList:not(.readonly) .ant-tag.ant-tag-checkable-checked { color: #FFF }

.interestsList.readonly { line-height: 24px; }
.interestsList.readonly .ant-tag { line-height: 18px; font-size: 10px; border-radius: 9px; min-height: 18px; padding-left: 10px; padding-right: 10px; margin: 0 4px 0 0; border: 0; color: #FFF; text-transform: uppercase; }
.interestsList.readonly .ant-tag:hover { border: 0; opacity: 1.0 }


.interest-0 { background: #CC0000 }
.interest-1 { background: #CC6600 }
.interest-2 { background: #CCCC00 }
.interest-3 { background: #66CC00 }
.interest-4 { background: #00CC00 }
.interest-5 { background: #00CC66 }
.interest-6 { background: #00CCCC }
.interest-7 { background: #0066CC }
.interest-8 { background: #0000CC }
.interest-9 { background: #6600CC }
.interest-10 { background: #CC00CC }
.interest-11 { background: #CC0066 }
.interest-12 { background: #606060 }



.interestDD { display: none }

.filter-interests .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector { overflow-x: hidden; text-overflow: ellipsis; overflow: hidden; text-align: left; }
.filter-interests .ant-select-multiple .ant-select-selection-item-remove { color: inherit; font-style: normal; line-height: 27px !important; text-align: center; text-transform: none; vertical-align: middle; text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color: rgba(0, 0, 0, 0.45); font-weight: bold; font-size: 12px; line-height: inherit; cursor: pointer; display: inline-block; font-size: 10px; }
.filter-interests .ant-select-multiple .ant-select-selection-item-remove > .anticon { vertical-align: middle !important; line-height: 21px !important; border: 2px solid #BBB; border-radius: 50%; height: 16px; width: 16px; margin-top: 4px; cursor: pointer; font-weight: bold; }
.filter-interests .ant-select-multiple .ant-select-selection-item-remove > .anticon:hover { opacity: 0.6 }
.filter-interests .ant-select-multiple .ant-select-selection-item-remove svg { display: inline-block; margin-top: -11px; }
.filter-interests .ant-select-multiple .ant-select-selection-item { position: relative; display: flex; flex: none; box-sizing: border-box; max-width: 100%; height: 32px; margin-top: 2px; margin-right: 4px; margin-bottom: 2px; padding: 0 4px 0 8px; line-height: 32px; background: #f5f5f5; border: 1px solid #f0f0f0; border-radius: 4px; cursor: default; transition: font-size 0.3s, line-height 0.3s, height 0.3s; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
