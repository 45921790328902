
/*-----------Loader-----------*/
.loading-container { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 10000; }
.loader { text-align: center; vertical-align: middle; width: 40px; height: 40px; position: relative; }

@media (max-width: 767px) {
  /*.loader { margin-left: calc(50% - 40px); }*/
}


/*.loader { margin: 100px auto; width: 40px; height: 40px; position: relative; }*/
.loader-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 1.0; z-index: 1000; background: rgba(255,255,255,0.5) !important; }

.picHolder { font-family: 'Jost', sans-serif; border-radius: 8px; margin: 15px; border: 2px; border-style: dashed; border-color: #808080; width: 120px; height: 120px; display: inline-block; font-size: 2rem; font-weight: bold; line-height: 120px; text-align: center; }

.cube1, .cube2, .cube3, .cube4 { background-color: #999; width: 30px; height: 30px; position: absolute; top: 0; left: 0; -webkit-animation: sk-cubemove 2.0s infinite ease-in-out; animation: sk-cubemove 2.0s infinite ease-in-out; }
.cube1, .cube3 { background: url(../../assets/img/symbol-o.png) center center no-repeat; background-size: contain; }
.cube2, .cube4 { background: url(../../assets/img/symbol-p.png) center center no-repeat; background-size: contain; }

.loader-small { width: 20px; height: 20px; }
.loader-small .cube1, .loader-small .cube2, .loader-small .cube3, .loader-small .cube4 { width: 15px; height: 15px; -webkit-animation: sk-cubemove-small 2.0s infinite ease-in-out; animation: sk-cubemove-small 2.0s infinite ease-in-out; }

.cube2 { -webkit-animation-delay: -0.5s !important; animation-delay: -0.5s !important; }
.cube3 { -webkit-animation-delay: -1.0s !important; animation-delay: -1.0s !important; }
.cube4 { -webkit-animation-delay: -1.5s !important; animation-delay: -1.5s !important; }

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% { transform: translateX(42px) rotate(-90deg) scale(0.5); -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% { transform: translateX(42px) translateY(42px) rotate(-179deg); -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg); }
  50.1% { transform: translateX(42px) translateY(42px) rotate(-180deg); -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% { transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% { transform: rotate(-360deg); -webkit-transform: rotate(-360deg); }
}

@-webkit-keyframes sk-cubemove-small {
  25% { -webkit-transform: translateX(21px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(21px) translateY(21px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(21px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove-small {
  25% { transform: translateX(21px) rotate(-90deg) scale(0.5); -webkit-transform: translateX(21px) rotate(-90deg) scale(0.5); }
  50% { transform: translateX(21px) translateY(21px) rotate(-179deg); -webkit-transform: translateX(21px) translateY(21px) rotate(-179deg); }
  50.1% { transform: translateX(21px) translateY(21px) rotate(-180deg); -webkit-transform: translateX(21px) translateY(21px) rotate(-180deg); }
  75% { transform: translateX(0px) translateY(21px) rotate(-270deg) scale(0.5); -webkit-transform: translateX(0px) translateY(21px) rotate(-270deg) scale(0.5); }
  100% { transform: rotate(-360deg); -webkit-transform: rotate(-360deg); }
}

/*----------Animate------------*/
.animate-from-bottom { transition: transform 4000ms cubic-bezier(.18, 1, .21, 1), opacity 2500ms cubic-bezier(.18, 1, .21, 1); }
.animate-from-bottom { transform: translateY(50px); opacity: 0.0; }
.animate-from-bottom.visible { transform: translateY(0); opacity: 1.0; }
