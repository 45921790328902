
/*Button Styles*/
button:focus { outline: 0px !important; }
.btn { font-size: 16px; font-family: 'Jost', sans-serif; /* min-width: 200px; */ vertical-align: middle; border-radius: 8px; padding: 0 15px; line-height: 50px; height: 50px; }

btn:focus { border: 0px !important; box-shadow: 0px !important; outline: 0px !important; }

.btn-primary { color: #fff; background-color: #303e46; border-color: #303e46; font-size: 16px; height: 50px; text-transform: uppercase; line-height: 50px; vertical-align: middle; }
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle { color: #fff; background-color: #526a77; border-color: #526a77; }
.btn-primary.focus, .btn-primary:focus { color: #fff; background-color: #435763; border-color: #435763; box-shadow: 0 0 0 0.2rem rgb(104, 130, 146); }
.btn.focus, .btn:focus { outline: 0; box-shadow: 0 0 0 0.2rem rgb(104, 130, 146); }
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgb(104, 130, 146); }
.btn-primary span { margin: 0; padding: 0; line-height: 50px; height: 50px; top: 0; border: 0; }
.btn-primary:disabled { color: #fff; background-color: #303e46; border-color: #303e46; }
.btn-primary:hover { background-color: #475b66; border-color: #475b66; color: #7cc2b3; }
.btn-primary:hover:disabled { background-color: #475b66; border-color: #475b66; }


.btn-secondary { color: #303e46; background-color: #fbfbfb; border-color: #fbfbfb; border: 1px solid; font-size: 16px; height: 50px; text-transform: uppercase; line-height: 50px; vertical-align: middle; min-width: 300px; }
.btn-secondary:disabled { color: #303e46; background-color: #fbfbfb; border-color: #fbfbfb; }
.btn-secondary:hover:disabled { color: #303e46; background-color: #b0b0b0; border-color: #b0b0b0; }

.btnCustom { font-family: 'Jost', sans-serif; min-height: 50px; height: 50px; vertical-align: middle; border-radius: 8px; margin: 5px; width: 48%; margin: 0px; border: 1px solid; font-size: 16px; }
btnCustom:focus { border: 0px !important; box-shadow: 0px !important; outline: 0px !important; }
.buttonActive { color: #ffffff; background-color: #e57d7e; border-color: #e57d7e; }
.buttonNotActive { color: #48555c; background-color: #ffffff; border-color: #48555c; }
.startButton { width: 100%; }
.endButton { width: 100%; }
.startButtonDiv { padding-right: 4% !important; }
.endButtonDiv { padding-left: 4% !important; }
.btn-white { border: 1px solid #bebebe; }
.btn-back-arrow { position: absolute !important; top: 15px; left: 15px; box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75) !important; -webkit-box-shadow: 0px 0px 15px rgba(0,0,0,0.15) !important; -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75); }
.btn-back-arrow.ant-btn-icon-only { width: 32px; height: 32px; padding: 2.4px 0; font-size: 16px; vertical-align: 0px; border-radius: 50%; z-index:1; }



@media (max-width: 767px) {
  .btn-back-arrow { top: 64px; z-index: 1; }
}
