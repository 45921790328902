
/*Form Styles*/
.form-val { color: #ff0000; font-size: 12px; }
.form-control { font-family: Georgia; }
input { background-color: #ffffff; border-color: #b0b0b0; color: #475b66; height: 50px; font-family: Georgia; font-size: 18px; }
input.form-control { box-shadow: none; height: 50px; font-size: 18px; }
input[type=text].form-control { box-shadow: none; }
input[type=text].form-control { box-shadow: none; background-color: #ffffff; border-color: #b0b0b0; color: #475b66; font-family: Georgia; border-radius: 4px; border: 1px solid #ddd; padding: 0 8px; }
input[type=text].form-control::placeholder { color: #BBB !important; }
input[type="date"]::-webkit-calendar-picker-indicator { display: none; -webkit-appearance: none; }
.datePicker { box-shadow: none; background-color: #ffffff; border-color: #b0b0b0; color: #475b66; font-family: Georgia; border-radius: 4px; border: 1px solid #ddd; padding: 0 8px; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus { -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important; }
.ant-select-selector { border-radius: 4px; }
.form-control:focus { color: #495057; background-color: #fff; border-color: rgba(165, 215, 205, 0.92); /* outline: 0; */ box-shadow: 0 0 0 .2rem rgba(86, 180, 162, 0.27); }
label { text-align: left; font-family: 'Jost', sans-serif; font-weight: bold; font-size: 14px; margin: 0 0 4px 0; width: 100%; padding: 0; }
.form-group { text-align: left; margin: auto; margin-top: 0; }

.ngb-dp-navigation-chevron { color: #56b4a2; }
/*-----------select----------------*/
.gender-picker { font-family: Georgia; font-size: 18px; }

::ng-deep textarea { font-size:18px !important; }

/*-----------slider----------------*/

.timeSlider .ant-slider nz-slider-handle .ant-slider-handle { background-color: #56b4a2; border: solid 2px #56b4a2; }
.timeSlider .ant-slider .ant-slider-rail { background-color: #cedbd8; }
.timeSlider .ant-slider nz-slider-track .ant-slider-track { background-color: #000000; }


/*----------date picker------------*/
nz-date-picker { width: 100%; border-radius: 3px; }

.datePicker div div input { font-size: initial; height: 50px; font-size: 18px }
