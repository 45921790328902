
/*----------Graph---------*/
.graph { position: relative; width: 100%; height: 100%; min-height: calc(100vh - 250px); }
.svg { position: absolute; width: fit-content; height: fit-content; top: 0; left: 0; }

.links line { stroke: #aaa; }
.nodes circle { stroke: #333; stroke-width: 2px; fill: rgba(0,0,0,0.0); cursor: pointer; }
.nodes .active {
    circle { stroke-width: 3px; }
    text { font-weight: 700; }
}
.nodes text { fill: rgba(0,0,0,1); font-size: 28px; font-family: 'Jost',sans-serif; }
text.link-label { fill: rgba(0,0,0,1); font-size: 16px; font-family: 'Jost',sans-serif; }
