/*---------------Messages--------------*/

@media (min-width: 1024px) {
  .messages .main-side { width: 320px }
}

.messages .main-content header.common-header { background: #FFF !important; z-index: 999; position: absolute; width: 100%; }



.common-message-content img { width: 100%; }
