
/*--------------Events---------------*/
.event-edit .main-image-upload .ant-upload { height: 100%; text-align: center; width: 100%; display: block; align-items: center; overflow: hidden; position: relative; }
.event-edit .main-image-upload .ant-upload img { position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; object-fit:contain;}
.event-edit .main-image-upload .ant-upload .upload-action { position: absolute; top: 0; left: 0; width: 100%; height: 100%; font-weight: bold; padding-top: 28px; color: #999; }




.icons-holder { width: 100%; height: 60px; top: 8px; position: relative; left: 8px; color: #ffffff; text-align: left; position: absolute; }
.icons-holder-pill { width: 100%; height: 60px; top: 8px; position: relative; left: 8px; color: #ffffff; text-align: left; position: absolute; }

.icons-holder > div { width: 50px; height: 50px; color: #ffffff; border-radius: 50%; text-align: center; line-height: 8px; display: inline-block; margin-right: 5px; vertical-align: top; padding-top: 11px; }
.icons-holder > div i {font-size:16px;}
.icons-holder > div span { font-size: 9px; }
.privacy-icon-holder { background-color: #e57d7e; }
.virtual-icon-holder { background-color: #303f46; }



.icons-holder-pill > div { width: 40px; height: 15px; color: #ffffff; border-radius: 10px; text-align: center; line-height: 11px; display: inline-block;  vertical-align: top; }
.icons-holder-pill > div i { font-size: 8px; }
.icons-holder-pill > div span { font-size: 8px; }
.privacy-icon-holder-pill { background-color: #e57d7e; margin-right:3px; }
.virtual-icon-holder-pill { background-color: #303f46; }



event .icons-holder { top: 45px; left: 45px; }

event .message-thread-inner { min-height: calc(100vh - -49px);}
