
/*----------Nav------------*/
.navbar { padding: 8px 0; }
.navbar .container { height: 60px; }
.navbar-nav { margin: auto; }
/*.add-media-action .avatar-uploader .ant-upload.ant-upload-select-picture-card { width: 104px; height: 104px; margin-right: 0px !important; margin-bottom: 0px !important; text-align: center; vertical-align: top; background-color: #fafafa; border: 2px dashed #e68183; border-radius: 8px; cursor: pointer; transition: border-color .3s; }
  .add-media-action .avatar-uploader .ant-upload.ant-upload-select-picture-card .ant-upload { color: #e68183; }*/



.navbar-brand { width: 36px; position: absolute; }
.navbar-brand img { width: 100%; }
.nav-actions { position: absolute; right: 15px; }
.nav-dropdown { float: right }
.nav-link { padding-right: 12px; padding-left: 12px; margin: 0; }
.nav-item { margin-left: 0; margin-right: 0; }
.nav-item a { transition: all 0.3s ease; margin: 0 15px; }
.nav-item a:hover { opacity: 0.5; }
.nav-item.link-active { opacity: 0.75 }

.nav-item a { transition: all 0.3s ease; margin: 0 15px; }
.nav-item a:hover { opacity: 0.6; }
.nav-item .nav-item-title { display: none; margin-left: 1rem; font-family: 'Jost', sans-serif; font-size: 1.2rem; }

@media (max-width: 767px) {
  .nav-item { margin-left: 0; margin-right: 0; /* text-align: center; */ }
  .nav-item .nav-item-title { display: initial; }
  .navbar { padding: 8px 0 }
  .navbar-nav { margin: auto; display: block }
  .navbar .container { height: 60px; padding: 0 15px !important; }

  .navbar-toggler { padding: .25rem .75rem; font-size: 1.25rem; line-height: 1; background-color: transparent; border: 1px solid transparent; border-radius: .25rem; position: absolute; right: 15px; }
  .navbar-collapse { align-items: center; margin-top: 30px; z-index: 100; position: fixed; left: 0; right: 0; top: 46px; bottom: 0; background: url(/assets/img/body-bg.jpg) repeat; transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); flex-basis: auto; flex-grow: 0 }
  .navbar .nav-actions { position: fixed; top: 15px; right: 86px; }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link { padding-right: 0; padding-left: 0; margin: 0 8px; }
}

@media (max-width: 992px) {
  .navbar { padding: 8px 0 }
  .navbar .container { height: 60px; padding: 0 15px !important; }
}

@media (min-width: 992px) {
  .navbar-expand-md .navbar-nav .nav-link { padding-right: 0; padding-left: 0; margin: 0 26px; }
}
