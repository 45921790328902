
/*Used for transitions*/
.container { position: relative; min-height: calc(100% - 160px); overflow-x: hidden; overflow-y: hidden; padding: 0; width: 100%; }

@media (max-width: 768px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 100%; margin: 0; padding: 0 !important; background: transparent; }
}

@media (max-width: 991px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 100%; margin: 0; padding: 0 !important; background: transparent; }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 962px; }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1140px !important; }
}



/*------------Two Col Layout---------------*/
.two-col-layout { background: #FFF; }
.two-col-layout .col-left { text-align: center; border-right: 1px solid #EEE; padding-right: 0; }
.two-col-layout .col-left h3 { margin: 15px 0; font-size: 20px; vertical-align: middle; line-height: 28px; font-family: Georgia; }
.two-col-layout .col-left p { margin: 8px 0; font-size: 14px; }

.two-col-layout h4 { font-size: 18px; margin-bottom: 15px; font-family: Georgia; line-height: 30px; vertical-align: middle; }

.two-col-layout h4 i { line-height: 30px; vertical-align: middle; display: inline-block; }
.two-col-layout h4 i svg { line-height: 30px; vertical-align: initial; }

/*
      .two-col-layout .col-left .image { width: 44%; margin: auto; margin: 58px 27% 8px; text-align: center; }
        .two-col-layout .col-left .image img { width: 100%; border-radius: 50%; height: auto; object-fit: cover; }*/
.two-col-layout .main-image { width: 100%; padding-top: 100%; position: relative; }
.two-col-layout .main-image img { position: absolute; top: 0; left: 0; bottom: 0; right: 0; WIDTH: calc(100% - 60px); height: calc(100% - 60px); object-fit: cover; border-radius: 50%; margin: 30px; }

.two-col-layout .col-right { padding-left: 0; padding-right: 0; }
.two-col-layout .col-right .ant-tabs-content-holder { padding-left: 0; background: #fbfbfb; padding-right: 15px; }
.two-col-layout .col-right .ant-tabs-tab { padding: 20px 0; }

.two-col-layout .col-right .ant-tabs > .ant-tabs-nav, .two-col-layout .col-right .ant-tabs > div > .ant-tabs-nav { position: relative; display: flex; flex: none; align-items: center; margin: 0 }
