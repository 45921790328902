/*font Imports*/
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@import './assets/styles/ant.scss';
@import './assets/styles/button.scss';
@import './assets/styles/card.scss';
@import './assets/styles/carousel.scss';
@import './assets/styles/events.scss';
@import './assets/styles/forms.scss';
@import './assets/styles/graph.scss';
@import './assets/styles/interests.scss';
@import './assets/styles/invites.scss';
@import './assets/styles/layout.scss';
@import './assets/styles/loader.scss';
@import './assets/styles/media.scss';
@import './assets/styles/members.scss';
@import './assets/styles/messages.scss';
@import './assets/styles/nav.scss';
@import './assets/styles/video.scss';

/* Font awosome*/
@font-face {
  font-family: 'FontAwesome';
  src: url('./assets/fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('./assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('./assets/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('./assets/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('./assets/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('./assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-linkedin:before {
  content: "\f0e1";
}

// .fa-linkedin-square:before {
//   content: "\f08c";
// }

.fa-linkedin-square:before {
  content: "\f08c";
  color: black;
}

// .fa-instagram:before {
//   content: "\f16d";
//   color: transparent;
//   background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
//   background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
//   background-clip: text;
//   -webkit-background-clip: text;
// }

.fa-instagram:before {
  content: "\f16d";
  color: black;
  background: none;
  -webkit-background-clip: initial;
  background-clip: initial;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/*Main Styles*/
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  background: url(./assets/img/body-bg.png) repeat;
  margin: 0;
  padding: 0;
}

.invite-container .ant-modal-content .ant-modal-body {
  background: url(./assets/img/body-bg.png) repeat;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.08);
}

a {
  color: #56b4a2;
}

a:hover {
  color: #489b8c;
}

h1 {
  font-size: 1.5rem;
  font-family: Georgia;
}

h2 {
  font-family: Georgia;
}

h3 {
  font-family: 'Jost', sans-serif;
}

.text-break-spaces {
  white-space: break-spaces;
}

.fontGeorgia {
  font-family: Georgia !important;
}

.fontJost {
  font-family: 'Jost', sans-serif !important;
}

/*#container { display: flex; flex-direction: column; justify-content: center; align-items: center; }*/
.main {}

:host {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullWidth {
  width: 100% !important;
}

.boxContainer {
  width: 800px;
  max-width: 100%;
  background-color: #fbfbfb;
  padding: 50px;
  margin: auto;
}

.containerBorder {
  border: 30px solid transparent;
  padding: 15px;
  border-image: url(./assets/img/borderImage.jpg) 40 round;
  border-radius: 8px;
  margin-top: 30px;
}

.centeredContent {
  text-align: center;
}

.titleSeparator {
  width: 50px;
  background-color: #000000;
}


.footer {
  width: 100%;
  text-align: center;
  padding: 30px 0;
  font-size: 12px;
  color: #999;
}

.art-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 200px;
  right: 0;
  width: 100%;
  background: url(./assets/img/art-bg.jpg) repeat-x bottom;
  z-index: 0
}

.art-bg2 {
  position: relative;
  top: 0;
  left: 0;
  height: 50px;
  right: 0;
  width: 100%;
  background: url(./assets/img/art-bg.jpg) repeat-x bottom;
  z-index: 0;
  background-size: contain;
}

nz-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}

.ant-progress-success-bg,
.ant-progress-bg {
  position: fixed !important;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
}

.ant-progress-inner {
  position: fixed !important;
  display: inline-block !important;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
  top: 0 !important;
}

.customCheckBox {
  width: auto;
  display: inline-block;
  height: 20px;
  vertical-align: text-top;
}

.red {
  color: #f25a12;
}

/*-------Misc--------*/
.background-grey {
  background-color: #ddd;
}

.background-white {
  background-color: #ffffff;
}


.fullWidth {
  width: 100%;
}

.p-t-15 {
  padding-top: 15px;
}

.m-b {
  margin-bottom: 15px;
}

.m-l-8 {
  margin-left: 8px;
}

.bg-transparent {
  background: transparent
}

.grey {
  color: #AAA;
}

.line-clamp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-grey {
  margin: 0px 0px 15px 0;
  padding: 15px;
  background: #fbfbfb
}

.box-grey .ant-page-header {
  background: none;
}

.uploadIcon {
  font-size: 18px;
}

.addEventIcon {
  font-size: 5.5rem;
  margin-top: 15%;
}

.addEventContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.cursorPointer {
  cursor: pointer;
}

.closeIcon {
  color: #9f9f9f !important;
  font-size: 0.9rem;
}

.inviteSelectInput:disabled {
  background-color: #ffffff !important;
  color: #3e3e3e !important;
  cursor: pointer !important;
}

.ant-input-affix-wrapper-disabled {
  background-color: #ffffff !important;
  color: #3e3e3e !important;
  cursor: pointer !important;
}

.grey-background {
  background-color: #fbfbfb;
}


/*----------------cookie consent-------------*/

.ucc-backdrop {
  background: url(./assets/img/body-bg.png) repeat !important;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 20%
}

.ucc-logo {
  width: 250px;
}

.ucc-button--secondary {
  display: none;
}

.ucc-button--primary {
  flex-basis: 100% !important;
}

.ucc-modal p {
  text-align: center;
}


/*----------------info card-------------*/
.listItem {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #000000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 16px;
  padding: 0px 0 0 7px;
}

.listItemContent {
  width: 80%;
  display: inline-block;
  padding-left: 10px;
}

.infoCard .ant-card-body {
  padding: 10px;
}

/* Importing Bootstrap SCSS file. */
/*@import '~bootstrap/scss/bootstrap';*/

.ucc-logo {
  margin-bottom: 1rem !important;
}

.ucc-buttons {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  display: block !important;
}

.ucc-button {
  background: #303e46 !important;
  color: #FFF !important;
  border: 2px solid #777777 !important;
  max-width: 150px !important;
  text-align: center !important;
  padding: 5px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  border-radius: 8px !important;
}

.ucc-button:hover {
  background-color: #475b66 !important;
  border-color: #475b66 !important;
  color: #7cc2b3 !important;
}

/*---------------scroll shadow---------------*/
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, .3);
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, .3);
}

/*---------------scroll shadow---------------*/
.event-video-height-fixes .ant-tabs-content-holder .ant-tabs-content {
  height: 100% !important;
}

.event-video-height-fixes .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  display: flex;
  flex-direction: column;
}

.galleryActive {
  display: inline;
  flex-direction: column;
}

/*-------------cookie consent -----------*/
.ucc-intro {
  font-size: 12px;
}


/*--------------Messages-------*/
.message-box {
  right: 0
}

.message-box button {
  border: 0
}


.message-thread {
  position: ABSOLUTE;
  padding: 15px 0;
  flex: 1;
  background-color: #fbfbfb;
  right: 0;
  left: 0;
  bottom: 50px;
  top: 0;
}

.message-box {
  position: sticky;
  bottom: 0;
  width: 100%;
}

.message-box button {
  border: 0;
}

.message-box {
  display: flex;
  flex-shrink: 0;
  background-color: #f0f0f0;
  padding-left: 12px;
}

.message-box .text-input {
  font-size: 14px;
  flex: 1;
  border: 0;
  padding: .5rem;
  margin-right: 12px;
  margin-left: 12px;
}

.message-box .text-input:empty::before {
  content: "Type a message";
  font-size: 15px;
  opacity: 0.5;
}

.message-box-item {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
}

.message-box-item>* {
  height: auto;
  line-height: 32px
}


.text-input:focus {
  outline: none;
}

.text-input {
  line-height: 1.25;
  border-radius: .5rem;
  margin-right: 15px !important;
}

.lh-50 {
  line-height: 50px;
}

.btn-disabled {
  cursor: default !important;
  background-color: #cccccc !important;
  color: #ffffff !important;
  border: 0px;
}

.ucc-button {
  max-width: fit-content !important
}