
/*---------------Video--------------*/
.video-container { padding: 8px; height: 100%; }
.video-container .p-element-outer { width: 100%; padding-top: 75%; position: relative; }
.video-container .p-element { position: absolute; top: 0; left: 0; bottom: 0; right: 0; }

/*.video-container .p-element { margin-bottom: 8px; border: 1px solid #BBB;overflow: hidden; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; }*/
/*.video-container .p-element { margin-bottom: 8px; border: 1px solid #BBB; overflow: hidden; }*/
/*.video-container .p-element { min-height: 130px; height: 100%; box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; }*/
.video-container .p-element img { width: 100%; height: 100%; object-fit: cover; }
.video-container .actions { position: absolute; top: 8px; right: 4px }
.video-container #preview { width: 100%; height: 100%; }
.video-container video { width: 100%; height: 100%; object-fit: cover; }
/*.video-container .masonry-item { margin-bottom: 8px; width: 200px; border: 1px solid #999; height: 180px; overflow: hidden; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; }*/
